<template lang="pug">
Page(:title="title", :items="items", :doc="doc")
  .s-vstack.my-3
    Info
    .link-box
      Download
      SortModifiers
    BizTaxRates
    Integration
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Download from "./Download";
import Info from "./Info";
import SortModifiers from "./SortModifiers";
import Integration from "/libs/components/Biz/Chowly/Integration";

export default {
  components: { Info, Download, SortModifiers, Integration },
  data() {
    return {
      title: "Menu",
      doc: "https://docs.selflane.com/biz/menu/",
      items: [
        { title: "Menus", url: "/menu/menus" },
        { title: "Courses", url: "/menu/courses" },
        { title: "Dishes", url: "/menu/dishes" },
        { title: "Modifiers", url: "/menu/modifiers" },
        { title: "Matrix Modifiers", url: "/menu/matrix" },
        { title: "Ingredients", url: "/menu/ingredients" },
      ],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.loadBizService();
    EventBus.$on("switch-biz", this.loadBizService);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.loadBizService);
  },
  methods: {
    async loadBizService() {
      if (!this.biz) return;
      const criteria = { biz: this.biz._id };
      const data = await this.$api.service.account.retrieve({ criteria });
      this.$store.dispatch("setBizService", data);
    },
  },
};
</script>
