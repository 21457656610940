// Show Online Orders in Selected Month
<template lang="pug">
v-dialog(v-model="dialog", scrollable)
  v-card(v-if="range")
    v-toolbar(dense, flat)
      .d-flex.flex-row.align-center
        .subtitle-2.mr-5 {{ title }}
        v-btn-toggle(v-model="selection", color="secondary", dense)
          v-btn(
            small,
            v-for="(item, index) in selectionItems",
            :key="index",
            :value="item.value"
          ) {{ item.text }}
    v-card-text
      ByDay(:title="title", v-if="selection")
      ByOrder(:title="title", v-else)
</template>
<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import ByDay from "./ByDay";
import ByOrder from "./ByOrder";

export default {
  name: "OnlineOrders",
  components: { ByOrder, ByDay },
  data: () => ({
    range: null,
    dialog: false,
    selection: true,
    selectionItems: [
      { text: "By Day", value: true },
      { text: "By Order", value: false },
    ],
  }),
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (!this.range) return "";
      return (
        moment(this.range.begin).format("M/D/YYYY") +
        " to " +
        moment(this.range.end).format("M/D/YYYY")
      );
    },
  },
  mounted() {
    EventBus.$on("show-online-ordes-with-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-online-ordes-with-detail", this.load);
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load(range) {
      if (!range || !this.biz) return;
      this.range = range;
      this.dialog = true;
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          created: { $gte: range.begin, $lt: range.end },
          status: { $gte: 0 },
          "orderer.type": 0,
        },
        select: "orderer needed created orderNumber status payment",
      };
      const result = await this.$api.order.list(params);
      this.setOrders(result);
    },
  },
};
</script>
