<template lang="pug">
v-col(cols="12", md="6")
  .block-cell
    .cell-title {{ title }}
      ClickToCopy(:text="url", icon, v-if="url")
    .cell-content
      a(:href="url", target="_blank", v-if="url") {{ url }}
      div(v-else) {{ hint || "Not Set Yet." }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    url: { type: String, default: "" },
    hint: { type: String, default: "" },
  },
};
</script>

<style lang="sass" scoped>
.block-cell
  display: flex
  flex-direction: column
  gap: 2px
  .cell-title
    color: #666
    font-size: 0.8rem
    font-weight: 600
    display: flex
    align-items: center
  .cell-content
    font-size: 0.8rem
    font-weight: 500
    color: black
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 1
</style>