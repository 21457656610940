<template lang="pug">
PricingGuard(:min_level="3")
  .s-vstack
    .d-flex.flex-row.align-center
      WeekSelector(
        :loading="loading",
        :timezone="timezone",
        :maxDate="maxDate",
        @select="load"
      )
      v-spacer
      IconBtn(
        @click="$refs.form.open()",
        color="secondary",
        title="Booking",
        icon="mdi-plus"
      )
    .s-vstack(v-if="futureGroups.length")
      h2 Current and Future Reservations
      Blocks(
        v-for="group in futureGroups",
        :key="group.date",
        :date="group.date",
        :items="group.items",
        :setting="setting"
      )
    .s-vstack(v-if="pastGroups.length")
      h2 Past Reservations
      Blocks(
        v-for="group in pastGroups",
        :key="group.date",
        :date="group.date",
        :items="group.items",
        :setting="setting"
      )
  CreateForm(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment";
import _ from "underscore";
import { mapGetters } from "vuex";
import Blocks from "./blocks";
import CreateForm from "/libs/components/Booking/BookedTable/Create";

export default {
  components: { Blocks, CreateForm },
  data() {
    return {
      loading: false,
      setting: {},
      maxDate: moment().add(1, "month").format("YYYY-MM-DD"),
      begin: moment().startOf("week").valueOf(),
      end: moment().endOf("week").valueOf(),
    };
  },
  computed: {
    ...mapGetters(["biz", "booked_tables"]),
    timezone() {
      return this.biz?.address?.timezone || moment.tz.guess();
    },
    pastGroups() {
      const cutoff = moment().startOf("day");
      let filtered = this.booked_tables.filter((o) =>
        moment(o.time).isBefore(cutoff)
      );
      return this.getGroups(filtered);
    },
    futureGroups() {
      const cutoff = moment().startOf("day");
      let filtered = this.booked_tables.filter((o) =>
        moment(o.time).isAfter(cutoff)
      );
      return this.getGroups(filtered);
    },
    bizId() {
      return this.biz?._id;
    },
  },
  watch: {
    bizId() {
      this.getSetting();
    },
  },
  mounted() {
    this.getSetting();
    EventBus.$on("switch-biz", async () => {
      try {
        await this.load();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    });
  },
  methods: {
    // group by date
    getGroups(items) {
      return _.chain(items)
        .groupBy((o) => moment(o.time).tz(this.timezone).format("YYYY-MM-DD"))
        .map((items, date) => {
          return { date, items: _.sortBy(items, "time") };
        })
        .sortBy("date")
        .value();
    },
    async getSetting() {
      if (!this.bizId) return;
      const criteria = { _id: this.bizId };
      this.setting = await this.$api.b.rsvtsetting.retrieve({ criteria });
    },
    async load(props) {
      const { begin, end } = props || {};
      this.begin = begin || this.begin;
      this.end = end || this.end;
      const bizId = this.biz?._id;
      if (!bizId) return;
      const criteria = {
        "seller.id": bizId,
        time: { $gt: this.begin, $lt: this.end },
      };
      this.loading = true;
      try {
        const result = await this.$api.rs.book.list({ criteria });
        this.$store.dispatch("setBookedTables", result);
        await this.loadBookedCredits();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadBookedCredits() {
      const ids = this.booked_tables.map((o) => o._id);
      if (!ids.length) {
        this.$store.dispatch("setBookedCredits", []);
        return;
      }
      const criteria = { booked_table: { $in: ids } };
      const result = await this.$api.rs.bookedCredit
        .list({ criteria })
        .catch(() => {});
      this.$store.dispatch("setBookedCredits", result || []);
    },
  },
};
</script>