<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title Edit Booked Table
    v-form(@submit.prevent="submit")
      v-card-text
        v-row
          v-col(cols="4")
            v-text-field(label="Name", v-model="name", required)
          v-col(cols="4")
            PhoneField(v-model="phone")
          v-col(cols="4")
            v-text-field(label="Size", v-model="party_size")
        DateTimePicker(v-model="time", :timezone="timezone")
        v-select(label="Item", v-model="booked_item_id", :items="items")
        v-text-field(label="Note", v-model="note")
        v-checkbox(
          v-model="confirm",
          label="Confirm to edit",
          color="secondary"
        )
      v-card-actions
        v-btn(
          type="submit",
          color="secondary",
          :loading="loading",
          :disabled="!confirm"
        ) Save
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      booked_table: null,
      name: "",
      phone: "",
      party_size: "",
      time: "",
      // save original time for comparison
      original_time: "",
      note: "",
      booked_item_id: "",
      confirm: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz", "rsvtsetting"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    items() {
      return (
        this.rsvtsetting?.items?.map((o) => {
          return { text: o.name, value: o._id };
        }) || []
      );
    },
  },
  methods: {
    async open(booked_table) {
      this.booked_table = booked_table;
      this.name = booked_table.customer.name;
      this.phone = booked_table.customer.phone;
      this.party_size = booked_table.party_size;
      this.time = booked_table.time;
      this.original_time = booked_table.time;
      this.note = booked_table.note;
      this.booked_item_id = booked_table.booked_item.id;
      this.confirm = false;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      try {
        const params = {
          bookedId: this.booked_table._id,
          name: this.name,
          phone: this.phone,
          party_size: this.party_size,
          time: this.time,
          note: this.note,
        };
        if (
          this.booked_item_id &&
          this.booked_item_id !== this.booked_table.booked_item.id
        ) {
          params.booked_item = {
            id: this.booked_item_id,
            name: this.items.find((o) => o.value === this.booked_item_id).text,
          };
        }
        const result = await this.$api.rs.book.custom("edit", params);
        this.$emit("update", result);
        const dates = this.getDates();
        await this.$api.rs.bookedDate.custom("recreate", {
          bizId: this.biz._id,
          dates,
        });
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    getDates() {
      const original = moment(this.original_time).format("YYYY-MM-DD");
      const newTime = moment(this.time).format("YYYY-MM-DD");
      if (original === newTime) return [original];
      return [original, newTime];
    },
  },
};
</script>