<template lang="pug">
Activities(title="Point Earn Activities", :items="result", unit="pts")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Activities from "./Activities";

export default {
  components: { Activities },
  data() {
    return { result: null };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      this.result = await this.$api.member
        .custom("dashboard/sales", params)
        .catch(() => null);
    },
  },
};
</script>
