<template lang="pug">
v-col(cols="6", sm="4", md="3", lg="2")
  .method-box
    .text-box
      .method-title {{ title }}
      .method-detail {{ detail }}
    v-switch(v-model="content", color="secondary")
</template>

<script>
export default {
  name: "SystemMethodBox",
  props: {
    title: { type: String, required: true },
    detail: { type: String, default: "" },
    value: { type: Boolean, required: true },
  },
  data() {
    return { content: this.value };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(oldVal, newVal) {
      if (oldVal == newVal) return;
      this.$emit("input", this.content);
    },
  },
};
</script>

<style lang="sass" scoped>
.method-box
  border: 1px solid #e0e0e0
  border-radius: 6px
  padding: 0 10px
  max-width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  gap: 10px
  .text-box
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    .method-title
      font-size: 1em
      font-weight: 500
    .method-detail
      font-size: 0.8em
      color: #666
</style>