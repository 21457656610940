<template lang="pug">
v-col(cols="6", sm="4", md="3", lg="2")
  .method-box
    .text-box
      .method-title {{ method.name }}
      .method-detail {{ method.desc }}
    div
      v-btn(icon, small, @click="open")
        v-icon(small, color="secondary") mdi-pencil
      v-btn(icon, small, @click="remove")
        v-icon(small, color="error") mdi-delete
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title Edit Payment Method
      v-form(@submit.prevent="editMethod")
        v-card-text
          v-text-field(label="Method Name", v-model="name")
          v-text-field(label="Description", v-model="desc")
        v-card-actions
          v-btn(color="secondary", type="submit") Save
</template>

<script>
import { validateMethod } from "./utils";
export default {
  name: "CustomMethodBox",
  props: {
    method: { type: Object, required: true },
  },
  data() {
    return {
      name: "",
      desc: "",
      dialog: false,
    };
  },
  methods: {
    remove() {
      this.$emit("remove", this.method);
    },
    open() {
      this.dialog = true;
      this.name = this.method.name;
      this.desc = this.method.desc;
    },
    editMethod() {
      try {
        const method = { name: this.name, desc: this.desc };
        validateMethod(method.name);
        this.$emit("edit", method);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.method-box
  border: 1px solid #e0e0e0
  border-radius: 6px
  padding: 10px
  max-width: 100%
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  gap: 10px
  .text-box
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    .method-title
      font-size: 1em
      font-weight: 500
    .method-detail
      font-size: 0.8em
      color: #666
</style>