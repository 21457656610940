<template lang="pug">
PricingGuard(:min_level="3")
  v-row
    v-col(cols="12")
      .d-flex.flex-row
        MonthSelector(@select="load", :startYear="2024", :futureMonths="3")
        Recreate(@refresh="load", :month="month")
    v-col(cols="12", md="6")
      StandardChart(
        maxWidth="100%",
        chartType="bar",
        showDownload,
        :input="tableChart",
        :showTotal="true"
      )
    v-col(cols="12", md="6")
      StandardChart(
        maxWidth="100%",
        chartType="bar",
        showDownload,
        :input="seatChart",
        :showTotal="true"
      )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import Recreate from "./Recreate";

export default {
  components: { Recreate },
  data() {
    return {
      month: moment().format("YYYY-MM"),
      items: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    // date range for the month (YYYY-MM) in 1-31 format
    labels() {
      const days = moment(this.month, "YYYY-MM").daysInMonth();
      if (!days) return [];
      const result = Array.from({ length: days }, (_, i) => String(i + 1));
      return result;
    },
    tableChart() {
      const values = this.labels.map((o) => {
        const date = o.toString().padStart(2, "0");
        return (
          this.items.find((p) => p.date.endsWith(`-${date}`))?.total_booked || 0
        );
      });
      return { title: "Booked Tables", labels: this.labels, values };
    },
    seatChart() {
      const values = this.labels.map((o) => {
        const date = o.toString().padStart(2, "0");
        return (
          this.items.find((p) => p.date.endsWith(`-${date}`))?.total_seats || 0
        );
      });
      return { title: "Total Guests", labels: this.labels, values };
    },
  },
  mounted() {
    EventBus.$on("switch-biz", this.load);
  },
  methods: {
    async load(options = {}) {
      const { name } = options;
      if (name) this.month = name;
      if (!this.month) return;
      if (!this.biz._id) return;
      const params = {
        criteria: {
          biz: this.biz._id,
          date: { $gte: this.month + "-01", $lte: this.month + "-31" },
        },
      };
      try {
        this.items = await this.$api.rs.bookedDate.list(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>