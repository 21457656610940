<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn.text-notransform.mx-1(
      outlined,
      small,
      color="secondary",
      @click="load()",
      v-on="on"
    )
      span Edit Dish Markers
      v-icon(small, right) mdi-pencil
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Dish Markers
      v-spacer
      IconBtn(@click.stop="more()", title="Item", icon="mdi-plus")
    v-divider
    v-form(@submit.prevent="save")
      simple-table(v-if="status")
        thead
          tr
            th Name
            th Kitchen Name (optional)
            th
        tbody
          tr(
            v-for="(item, index) in dishes",
            :key="index",
            draggable="true",
            @dragstart="drag(index, $event)",
            @dragend="dragend($event)",
            @drop="drop(index, $event)",
            @dragover.prevent=""
          )
            td
              .d-flex.flex-row.align-center
                v-icon.mr-2(x-small) mdi-menu
                v-text-field(
                  dense,
                  v-model.trim="item.name",
                  hide-details,
                  single-line,
                  @keydown.down="more()"
                )
            td
              v-text-field(
                dense,
                v-model.trim="item.abbr",
                hide-details,
                single-line,
                @keydown.down="more()"
              )
            td
              v-btn(icon, small, @click.stop="less(index)", tabindex="-1")
                v-icon(color="red") mdi-close
      v-card-text
        v-row
          v-col(cols="6")
            v-checkbox(
              v-model="status",
              label="Use Markers",
              color="secondary"
            )
          v-col(cols="6")
            v-select(
              v-model="position",
              :items="positions",
              label="Position",
              hint="POS v6.42+",
              persistent-hint
            )
        .body-2.mt-3 Used in Point of Sale. Kitchen Name is optional and can be an abbreviation or a 2nd language name.
      v-card-actions
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      status: true,
      dishes: [],
      position: "bottom",
      positions: [
        { text: "Bottom of modifiers", value: "bottom" },
        { text: "Top of modifiers", value: "top" },
      ],
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    async load() {
      this.dishes = [];
      if (!this.biz) return;
      const criteria = { biz: this.biz._id };
      const result = await this.$api.menu.marker.retrieve({ criteria });
      this.dishes = result?.dishes || [];
      this.status = result?.status;
      this.position = result?.position || "bottom";
    },
    more() {
      this.dishes.push({ name: "", abbr: "" });
    },
    less(index) {
      this.dishes.splice(index, 1);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.dishes) return;
      if (index < 0 || index >= this.dishes.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.dishes.length)
        return;
      let dishes = JSON.parse(JSON.stringify(this.dishes));
      let tmp = JSON.parse(JSON.stringify(dishes[this.draggingIndex]));
      dishes.splice(this.draggingIndex, 1);
      dishes.splice(index, 0, tmp);
      this.dishes = JSON.parse(JSON.stringify(dishes));
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      const dishes = this.dishes
        ?.filter((o) => o.name)
        ?.map((o) => {
          return { name: o.name, abbr: o.abbr, indic: true, ul: true };
        });
      const params = {
        biz: this.biz._id,
        dishes,
        status: this.status,
        position: this.position,
      };
      try {
        await this.$api.menu.marker.create(params);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
