<template lang="pug">
div
  IconBtn(icon="mdi-sort", title="Sort Modifiers", @click="open")
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title
        h3 Sort Modifiers
      v-form(@submit.prevent="save")
        v-card-text
          v-select(v-model="sortModifiers", label="Sort By", :items="items")
          .caption {{ note }}
        v-card-actions
          v-btn(type="submit", color="secondary", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      sortModifiers: "auto",
      items: [
        { text: "Required modifers first, then optionals", value: "auto" },
        { text: "Manual sorting set on each dish", value: "manual" },
      ],
      note: "This governs how the modifiers are presented in the POS app and customer ends.",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    open() {
      this.sortModifiers = this.biz.sortModifiers || "auto";
      this.dialog = true;
    },
    async save() {
      const params = {
        criteria: {
          _id: this.biz._id,
        },
        action: {
          $set: {
            sortModifiers: this.sortModifiers,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>