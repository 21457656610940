/**
 * Validate method name
 * @param {*} name 
 * @param {*} custom: Array of custom methods ({name, desc})
 */

export function validateMethod(name, custom) {
  if (!name) throw new Error("Method name is required");
  const systemMethods = [
    "stripe",
    "card",
    "debit",
    "cash",
    "excard",
    "gift",
    "check",
    "reward",
    "booked",
  ];
  // if methods matches any of the system methods, with ignore case. reject it
  if (systemMethods.includes(name.toLowerCase()))
    throw new Error("Method name is reserved");
  const some = custom?.some((m) => m.name === name.name);
  if (some) throw new Error("Method name already exists");
}