<template lang="pug">
div
  v-col(cols="6", sm="4", md="3", lg="2")
    v-btn(icon, small, @click="open")
      v-icon mdi-plus
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title Add Payment Method
      v-form(@submit.prevent="addMethod")
        v-card-text
          v-text-field(label="Method Name", v-model="name")
          v-text-field(label="Description", v-model="desc")
        v-card-actions
          v-btn(color="secondary", type="submit") Add
</template>

<script>
import { validateMethod } from "./utils";
export default {
  props: {
    custom: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialog: false,
      name: "",
      desc: "",
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    addMethod() {
      try {
        validateMethod(this.name);
        const method = { name: this.name, desc: this.desc };
        this.$emit("add", method);
        this.name = "";
        this.desc = "";
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>