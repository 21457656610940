<template lang="pug">
Block(title="Links")
  v-row(dense)
    Cell(
      v-for="(item, index) in items",
      :key="index",
      :title="item.title",
      :url="item.content",
      :hint="item.hint"
    )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Block from "../Block.vue";
import Cell from "./Cell.vue";

export default {
  components: { Block, Cell },
  data() {
    return { program: null };
  },
  computed: {
    ...mapGetters(["biz"]),
    online_store() {
      return "https://www.selflane.com/bizs/" + this.biz.url;
    },
    catering() {
      const some = this.biz?.menus?.some((menu) => menu.catering);
      if (!some) return null;
      return "https://www.selflane.com/catering/" + this.biz.url;
    },
    booking() {
      return "https://www.selflane.com/rs/" + this.biz.url;
    },
    program_url() {
      return this.program
        ? "https://www.selflane.com/member_program/" + this.program._id
        : null;
    },
    items() {
      let memberTitle = "Member Signup Page";
      if (this.program?.bizs?.length > 1) {
        memberTitle = `Member Signup Page (shared by ${this.program.bizs.length} businesses)`;
      }
      return [
        { title: "Online Store Page", content: this.online_store },
        {
          title: "Catering Page",
          content: this.catering,
          hint: "Please add catering menus in Menu section",
        },
        { title: "Table Booking Page", content: this.booking },
        { title: memberTitle, content: this.program_url },
      ];
    },
  },
  mounted() {
    this.loadProgram();
    EventBus.$on("switch-biz", this.loadProgram);
  },
  methods: {
    async loadProgram() {
      if (!this.biz) return;
      const params = { criteria: { bizs: this.biz._id } };
      try {
        this.program = await this.$api.memberProgram.retrieve(params);
      } catch (e) {
        this.program = null;
      }
    },
  },
};
</script>
