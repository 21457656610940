<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open", v-on="on")
  v-card(v-if="orderType")
    v-card-title
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(align="center", wrap)
          v-col(cols="6")
            v-checkbox(
              color="secondary",
              v-model="orderType.pickup.status",
              label="Pick up",
              hide-details
            )
          v-col(cols="6", v-if="orderType.pickup.status")
            LeadTimePicker(v-model="orderType.pickup.beforehand")
        v-row(align="center", wrap)
          v-col(cols="6")
            v-checkbox(
              color="secondary",
              v-model="orderType.curbside.status",
              label="Curbside",
              hide-details
            )
          v-col(cols="6", v-if="orderType.curbside.status")
            LeadTimePicker(v-model="orderType.curbside.beforehand")
          v-col(cols="12", v-if="orderType.curbside.status")
            v-text-field(
              v-model="orderType.curbside.note",
              label="Curbside Instruction",
              dense,
              hide-details
            )
        v-row(align="center", wrap)
          v-col(cols="6")
            v-checkbox(
              color="secondary",
              v-model="orderType.dinein.status",
              label="Dine in",
              hide-details
            )
          v-col(cols="6", v-if="orderType.dinein.status")
            LeadTimePicker(v-model="orderType.dinein.beforehand")
        v-checkbox(
          color="secondary",
          v-model="orderType.gc.status",
          label="Gift Card",
          hide-details
        )
        v-divider.my-2
        v-checkbox(
          color="secondary",
          v-model="orderType.limited_access.status",
          label="Limited Access",
          hint="Check if only certain customers can order online.",
          persistent-hint
        )
        div(v-if="orderType.limited_access.status")
          v-text-field(
            v-model="orderType.limited_access.title",
            label="Required ID/Code",
            hint="e.g. \"Member ID\". Leave blank if not needed.",
            persistent-hint
          )
          v-text-field(
            v-model="orderType.limited_access.note",
            label="Note/Explanation",
            hint="e.g. \"This is a member-only service. Please enter your member ID to proceed.\"",
            persistent-hint
          )
          p Please understand that this feature is to prevent unauthorized access to your online ordering system. However, it doesn't validate the ID/Code. You will need to verify the ID/Code if needed.
        v-divider.my-2
        v-checkbox(
          color="secondary",
          v-model="print_online",
          label="Has Printer for Online Order",
          hint="We will routinely check printing status and ensure it is printed properly.",
          persistent-hint
        )
        v-checkbox(
          color="secondary",
          v-model="tip_online",
          label="Online Tip",
          hint="Allow customers to tip online.",
          persistent-hint
        )
        v-text-field(
          type="number",
          v-model="tipMax",
          label="Max Tip (%)",
          hint="Maximum tip percentage allowed.",
          persistent-hint,
          v-if="tip_online",
          :min="10",
          :max="100",
          :step="5"
        )
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters } from "vuex";
import LeadTimePicker from "/libs/components/Inputs/LeadTimePicker";

export default {
  components: { LeadTimePicker },
  data() {
    return {
      dialog: false,
      orderType: null,
      print_online: false,
      tip_online: true,
      tipMax: 50,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      if (!this.biz) return;
      let data = JSON.parse(JSON.stringify(this.biz.orderType));
      if (!data.limited_access) {
        data.limited_access = { status: false, title: "", note: "" };
      }
      this.orderType = data;
      this.print_online = this.biz.print_online || false;
      this.tip_online = this.biz.settings?.tip_online;
    },
    validate() {
      if (this.tip_online) {
        if (!this.tipMax) {
          throw new Error("Please provide a maximum tip percentage.");
        }
        if (this.tipMax < 10 || this.tipMax > 100) {
          throw new Error("Tip percentage must be between 10% and 100%.");
        }
      }
      if (this.orderType?.limited_access?.status) {
        if (!this.orderType.limited_access.note) {
          throw new Error(
            "Please provide a note/explanation for limited access."
          );
        }
      }
      if (this.orderType?.delivery?.deliv) {
        if (this.orderType.pickup?.status) {
          if (this.orderType.pickup.beforehand < 120) {
            this.orderType.delivery.beforehand = Math.max(
              this.orderType.pickup.beforehand + 20,
              30
            );
          } else {
            this.orderType.delivery.beforehand =
              this.orderType.pickup.beforehand;
          }
        }
      }
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      try {
        this.validate();
        const action = {
          $set: {
            orderType: this.orderType,
            print_online: this.print_online,
            "settings.tip_online": this.tip_online,
            "settings.tipMax": this.tipMax,
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
