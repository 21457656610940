<template lang="pug">
v-card(v-if="biz", flat)
  v-card-text
    .s-vstack
      Block(title="Online Store", :items="items")
        Form
      Catering
      Links
</template>

<script>
import { mapGetters } from "vuex";

import Block from "./Block";
import Catering from "./Catering";
import Form from "./Form";
import Links from "./Links";

export default {
  components: { Form, Block, Catering, Links },
  computed: {
    ...mapGetters(["biz"]),
    items() {
      let data = [];
      const leadtime = this.$options.filters.leadtime;
      if (this.biz.orderType.pickup?.status) {
        data.push({
          title: "Pick Up",
          content: leadtime(this.biz.orderType.pickup.beforehand),
        });
      } else {
        data.push({ title: "Pick Up", content: "Not Available" });
      }
      if (this.biz.orderType.curbside?.status) {
        data.push({
          title: "Curbside",
          content: leadtime(this.biz.orderType.curbside.beforehand),
        });
      }
      if (this.biz.orderType.dinein?.status) {
        data.push({
          title: "Dine In",
          content: leadtime(this.biz.orderType.dinein.beforehand),
        });
      }
      data.push({
        title: "eGift Card",
        content: this.biz.orderType.gc?.status ? "Enabled" : "Disabled",
      });
      if (this.biz?.settings?.tip_online == false) {
        data.push({ title: "Online Tip", content: "No Tip" });
      } else {
        const tipMax = this.biz?.settings?.tipMax || 50;
        data.push({
          title: "Max Tip",
          content: `${tipMax}%`,
        });
      }
      if (this.biz.orderType.limited_access?.status) {
        data.push({
          title: "Limited Access",
          content: this.biz.orderType.limited_access.title,
        });
      }
      return data;
    },
  },
};
</script>
