<template lang="pug">
v-row
  v-col(cols="12")
    .subtitle-2 {{ date | date({ dynamic: true }) }}
  v-col(cols="12", sm="6", md="4", v-for="group in groups", :key="group.id")
    Cell(:group="group", :setting="setting", @open="handleOpen")
  Detail(
    ref="detail",
    :setting="setting",
    @refund="handleRefund",
    @cancel="handleCancel",
    @edit="handleEdit"
  )
  Edit(ref="edit", @update="handleUpdate")
  Refund(ref="refund", @update="handleUpdate")
  Cancel(ref="cancel", @update="handleUpdate", :setting="setting")
</template>

<script>
import _ from "underscore";
import Cancel from "./../cancel";
import Refund from "./../refund";
import Cell from "./cell";
import Detail from "./detail";
import Edit from "./edit";

export default {
  components: { Cell, Detail, Refund, Cancel, Edit },
  props: {
    date: { type: String, default: "" }, // YYYY-MM-DD
    items: { type: Array, default: () => [] },
    setting: { type: Object, default: () => ({}) }, // rsvtsetting
  },
  computed: {
    // group items by booked_item.id
    // sort by time
    groups() {
      return _.chain(this.items)
        .groupBy((o) => o.booked_item?.id)
        .map((items, id) => {
          const name =
            this.setting?.items?.find((o) => o._id === id)?.name || id;
          return {
            id,
            name,
            items: _.sortBy(items, "time"),
          };
        })
        .sortBy("id")
        .value();
    },
  },
  methods: {
    handleOpen(item) {
      this.$refs.detail.open(item);
    },
    handleEdit(item) {
      this.$refs.edit.open(item);
    },
    handleRefund(item) {
      this.$refs.refund.open(item);
    },
    handleCancel(item) {
      this.$refs.cancel.open(item);
    },
    handleUpdate(item) {
      this.$store.dispatch("updateBookedTable", item);
      this.handleOpen(item);
    },
  },
};
</script>