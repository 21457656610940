<template lang="pug">
v-dialog(v-model="dialog", width="500", persistent)
  template(v-slot:activator="{ on }")
    div
      IconBtn(v-on="on", icon="mdi-download", title="Menu in PDF")
  v-card
    v-card-title
      h3 Download Menu
    v-card-text
      v-switch(
        v-model="cardPrice",
        label="With Card Price Upcharge",
        color="secondary"
      )
      div(v-if="cardPrice")
        v-row
          v-col(cols="12", md="6")
            v-text-field(
              v-model="percentage",
              label="Percentage",
              type="number",
              suffix="%"
            )
          v-col(cols="12", md="6")
            v-text-field(
              v-model="priceRounding",
              label="Price Rounding",
              type="number",
              suffix="cents"
            )
    v-card-actions
      v-btn(@click="download", color="secondary", text) Download
      v-btn(@click="dialog = false", text) Close
</template>

<script>
import { mapGetters } from "vuex";
import PDF from "/libs/utils/PDF";

export default {
  data() {
    return {
      dialog: false,
      cardPrice: false,
      percentage: 3, // default to 3%
      priceRounding: 5, // default to 5 cents
    };
  },
  computed: {
    ...mapGetters(["biz", "bizmodifiers"]),
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async download() {
      if (!this.biz) return;
      let doc = new PDF();
      const priceWidth = 22;
      const gap = 5;
      let nameWidth = doc.pageWidth - 2 * doc.xStart - priceWidth;
      if (this.cardPrice) {
        nameWidth -= priceWidth + gap;
      }
      doc.addTitle(`Menu for ${this.biz.name}`);

      for (const course of this.biz.courses) {
        doc.addLine();
        doc.addContent(course.name, {
          fontWeight: "bold",
          fontSize: 11,
        });
        doc.nextCell(nameWidth + gap);
        if (this.cardPrice) {
          doc.addContent("Cash Price");
          doc.nextCell(priceWidth + gap);
          doc.addContent("Card Price");
        } else {
          doc.addContent("Price");
        }
        doc.nextLine(15);
        this.processCourse(course, doc, nameWidth, priceWidth, gap);
        doc.nextLine(5, 50); // ensure there is enough space for the next course, otherwise new page
      }
      // save the file
      doc.save("menu");
      this.dialog = false;
    },
    async processCourse(course, doc, nameWidth, priceWidth, gap) {
      for (const dish of this.biz.dishes) {
        if (dish.course != course._id) continue;
        // pad name to 60 characters
        const name = dish.name.padEnd(60, " ");
        const price = dish.price;
        let newPrice = price;
        // price are in dollars, rounding 7.53 to 7.55 if the rounding is 5 cents and 7.72 to 7.70
        if (this.cardPrice) {
          newPrice += (price * this.percentage) / 100;
          if (newPrice != 0) {
            newPrice = Math.round(newPrice * 100) / 100;
            if (this.priceRounding != 0) {
              let cents = newPrice * 100;
              cents =
                Math.round(cents / this.priceRounding) * this.priceRounding;
              newPrice = cents / 100;
            }
          }
        }
        doc.addContent(name, { fontWeight: "bold" });
        doc.nextCell(nameWidth + gap);
        doc.addContent(`$${price}`);
        if (this.cardPrice) {
          doc.nextCell(priceWidth + gap);
          doc.addContent(`$${newPrice.toFixed(2)}`);
        }
        doc.nextLine();
        // add description
        if (dish.description) {
          const dim = doc.addContent(dish.description, {
            fontStyle: "italic",
            fontSize: 8,
            maxWidth: nameWidth,
            textColor: "#818181",
          });
          doc.nextLine(dim.h + 5);
        }
        // add modifiers
        if (!dish.modifierIDs?.length) continue;
        for (const id of dish.modifierIDs) {
          const modifier = this.bizmodifiers?.find(
            (o) => o.modifier?._id == id
          )?.modifier;
          if (!modifier) continue;
          const choices = modifier.choices.map((o) => o.name).join(", ");
          const dim = doc.addContent(choices, {
            fontStyle: "italic",
            fontSize: 8,
            maxWidth: nameWidth,
          });
          doc.nextLine(dim.h + 1);
        }
        doc.nextLine(4);
      }
    },
  },
};
</script>